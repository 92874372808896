<template>
<html lang="en" class="notranslate" translate="no"><!-- All translators -->
      <head><meta name="google" content="notranslate" /> <!-- Just for google -->
    </head>
  <div class="app">
    <Menu :list="menuList"/>
    <body>
        <div class="mx-auto">
            <div class="flex-1 bg-gray-1 px-0 py-0 m-0">
                    <div class="flex bg-gray-000 h-34 grid grid-cols-1 lg:grid-cols-1 lg:grid-cols-2 lg:grid-cols-2;">
                        <div class="collapse dont-collapse-sm">
                            <img src="@/assets/imagen-contacto.png" class="img-responsive min-h-full w-full" loading="lazy">
                        </div>
                        <div class="center sm:mx-20">
                            <div class="col-xs-1"></div>
                            <div class="col-xs-10 py-16">
                                <div>
                                    <p class="title pt-4 ml-5 text-left">Contacto</p>
                                    <p class="ml-5 text-left RobotoRegular text-lg" style="color: #726659">Para cualquier duda o aclaración, puedes enviarnos un mensaje</p>
                                </div>
                                    <form @submit="enviarMensaje" class="px-5 pt-3 mb-4 text-left" >
                                        <div class="form-group my-2">
                                            <p class="text-left mt-3 mb-1 RobotoLight text-base" style="color: #8e857a" >Nombre completo</p>
                                            <input type="text" class="RobotoRegular shadow appearance-none border rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="Input_NameContact" 
                                            v-model="nameContact" required>
                                        </div>
                                        <div class="form-group my-2">
                                            <p class="text-left mt-3 mb-1 RobotoLight text-base" style="color: #8e857a">Correo electrónico</p>
                                            <input type="email" style="text-transform: lowercase; margin-top:-5px;" class="RobotoRegular shadow appearance-none border rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="Input_EmailContact"
                                                pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" v-model="email" required>
                                        </div>
                                        <div class="form-group my-2">
                                            <p class="text-left mt-3 mb-1 RobotoLight text-base" style="color: #8e857a">Celular</p>
                                            <input type="number" class="RobotoRegular shadow appearance-none border rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="Input_CellPhoneContact" minlength="10" 
                                                pattern="[0-9]" v-model="numberContact">
                                        </div>
                                        <div class="form-group my-2">
                                            <p class="text-left mt-3 mb-1 RobotoLight text-base" style="color: #8e857a" >Mensaje</p>
                                            <textarea class="RobotoRegular shadow appearance-none border rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="Input_MessageContact" v-model="mensaje" name="Text" cols="40" rows="5" required></textarea>
                                        </div>
                                        <center>
                                        <button class="btn btn-primary btn-lg mt-5" id="button_popups" type="submit">
                                          Enviar Mensaje
                                        </button>
                                        </center>
                                    </form>
                                <br>
                             
                               <div class="px-8 pt-6 pb-8 mb-4" style="color:#80756a">
                                 <div>
                                   <p class="RobotoRegular text-left text-lg">También puedes encontrarnos por los siguientes medios:</p>
                                 </div>
                                <!-- Contacto -->
                                <div class="mt-10">
                                  <!-- ubicacion -->
                                    <div style="display:flex">
                                      <p class="mr-5" >
                                         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                                        </svg>
                                      </p>
                                      <p class="RobotoRegular text-left text-base">
                                        Bahía San Carlos #1056, Colonia Prados del Tepeyac, C.P. 85150,
                                        Cajeme, Ciudad Obregón, Sonora.
                                      </p>
                                    </div>
                                  <!-- ubicacion -->
                                  <!-- ubicacion -->
                                    <div class="flex mt-5">
                                      <p class="mr-5" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-phone-fill" viewBox="0 0 16 16">
                                            <path d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z"/>
                                        </svg>
                                      </p>
                                      <p class="text-base text-info text-left RobotoRegular">
                                        (644) 416-4346
                                      </p>
                                    </div>
                                  <!-- ubicacion -->

                                <!-- ubicacion -->
                                    <div class="flex mt-5">
                                      <p class="mr-5" >
                                         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                        </svg>
                                      </p>
                                      <p class="text-base text-info text-left RobotoRegular">
                                        Horario de atención de 8:00 A.M a 3:00 P.M de Lunes a Viernes
                                      </p>
                                    </div>
                                  <!-- ubicacion -->

                                  <!-- ubicacion -->
                                    <div class="flex mt-5">
                                      <p class="mr-5" >
                                         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                                          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                                          </svg>
                                      </p>
                                      <p class="text-base text-info text-left RobotoRegular">
                                        clientes@foncabsa.com
                                      </p>
                                    </div>
                                  <!-- ubicacion -->
                                </div>
                                 <!-- Fin contacto -->
                               </div>
         
                            </div>
                            <div class="col-xs-1"></div>
                       
                        </div>
                    </div>
                </div>
        </div>
    </body>

    <Footer/>

  </div>
  
</html>
</template>




<script>
import('@/assets/css/stylesheet.css')

import Menu from '../components/Menu.vue'
import Footer from '../components/Footer3.vue'
import Swal from "sweetalert2";
import menuOptions  from "@/helpers/menu.js";
import { ref, onBeforeMount } from 'vue';

export default {

  name: 'FormularioContacto',
  setup(){
    let menuList = ref([]);

    onBeforeMount(async () => {
      await getMenuOptionsList();
    });

    const getMenuOptionsList = async () => {
      await menuOptions.getMenuOptions().then(resp => {
        menuList.value = resp.data.FONCABSA;
      }).catch(err => {
        console.log("🚀 ~ file: Home.vue:223 ~ getMenuOptions ~ err", err);
      });
    };

    return {
      menuList,
      errors: [],
      tokenMessage:null,
      nameContact:"",
      email:"",
      numberContact:"",
      mensaje:"",
      ccapi: process.env.VUE_APP_CCAPI,
      business: process.env.VUE_APP_BUSINESS_FONCABSA,
      };
  },
  components: {
    Menu,
    Footer
  },
  beforeMount() {
  },
  methods:{
    async enviarMensaje(e){
        e.preventDefault();
        this.encriptarMensaje()

        await this.axios.post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/send_mail`, {
            business: "FONCABSA",
            mail:{
                business:  "FONCABSA",
                process_type: "solicitud de contacto",
                title: "Interesado foncabsa",
                body_mail: this.tokenMessage, 
                mailing_list: ["clientes@foncabsa.com"],
                attachment_list: []
            }
        },
        {
            'Content-Type': 'application/json'
        })
        .then(response => {
            Swal.fire({
              title: "Aviso",
              text: "Se envió el mensaje correctamente",
              icon: "success",
              confirmButtonColor: '#FEB72B',
            });
            // window.location.href = "/"
            this.$router.push({path:"/"});
        }).catch(error => {
              Swal.fire({
                title: "Aviso",
                text: "No pudo ser enviado el mensaje.",
                icon: "error",
                confirmButtonColor: '#FEB72B',
              });
        });
    },
    encriptarMensaje(){
        const header = { "alg": "HS256", "typ": "JWT" }
        const encodedHeader = Buffer.from(JSON.stringify(header)).toString('base64')

        var data = {
            "html": "Informacion del interesado<br>Nombre: "+this.nameContact+"<br>Correo: "+this.email+"<br>Celular: "+this.numberContact+"<br><br><br>"+this.mensaje
        }
        const encodedPayload = Buffer.from(JSON.stringify(data)).toString('base64')
        var jwtSecret = process.env.VUE_APP_JWT_SECRET;
        const crypto = require('crypto')
        const signature = crypto.createHmac('sha256', jwtSecret).update(encodedHeader + '.' + encodedPayload).digest('base64')      
        this.tokenMessage = encodedHeader+"."+encodedPayload+"."+signature
    },
  }
}
</script>

<style scoped>
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}

/* @media only screen and (min-width: 766px) {
  .collapse .dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
} */

@media only screen and (max-width: 1000px) {
  .collapse  {
    display: none;
    /* height: auto !important;
    visibility: visible; */
  }
}
#button_popups {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #FEB72B;
  height:50px; 
  width:100%;
  background-color:#FFCC00;
  border:1px solid #FFCC00;
  color:#ffffff;
  transition: all 300ms;
}
button {outline: none !important;}

input[type="text"]:focus-visible,
input[type="email"]:focus-visible,
input[type="number"]:focus-visible, 
textarea:focus-visible{
  border: 2px solid #810042 ;
  outline: none;
}

#button_popups:hover{
  background: #ad8a00;
  border:1px solid #ad8a00;
}

.text-info{
  font-family: "RobotoRegular";
}
</style>